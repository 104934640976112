/* eslint-disable */
/* tslint:disable */
export type ILocaleKeys = {
    checkout: {
      /* checkout.header_default_title */
      /* SECURE CHECKOUT */
      header_default_title: () => string; 
      header: {
        /* checkout.header.store_title_suffix */
        /* CHECKOUT */
        store_title_suffix: () => string; 
      }; 
      place_order: {
        /* checkout.place_order.place_order_button */
        /* Place Order */
        place_order_button: () => string; 
        /* checkout.place_order */
        /* Review & place order */
        $value: () => string; 
        /* checkout.place_order.description */
        /* Review your details above and continue when you're ready. */
        description: () => string; 
      }; 
      place_order_fast_flow: {
        /* checkout.place_order_fast_flow.delivery_method_title */
        /* Delivery method */
        delivery_method_title: () => string; 
        /* checkout.place_order_fast_flow.title */
        /* Review and place your order */
        title: () => string; 
        /* checkout.place_order_fast_flow.shipping_details_title */
        /* Delivery details */
        shipping_details_title: () => string; 
        /* checkout.place_order_fast_flow.billing_details_title */
        /* Billing Details */
        billing_details_title: () => string; 
        /* checkout.place_order_fast_flow.zip_code_title */
        /* Enter a zip / postal code */
        zip_code_title: () => string; 
        zip_code: {
          /* checkout.place_order_fast_flow.zip_code.apply */
          /* Apply */
          apply: () => string; 
        }; 
        /* checkout.place_order_fast_flow.region_select_title */
        /* Choose a {{subdivision}} */
        region_select_title: (data: Record<'subdivision', unknown>) => string; 
        /* checkout.place_order_fast_flow.delivery_details_title */
        /* Delivery details */
        delivery_details_title: () => string; 
      }; 
      page: {
        generalPaymentError: {
          /* checkout.page.generalPaymentError.title */
          /* We couldn't process your payment */
          title: () => string; 
          /* checkout.page.generalPaymentError.subtitle */
          /* Please try again or contact the site owner. */
          subtitle: () => string; 
          /* checkout.page.generalPaymentError.cta */
          /* Got It */
          cta: () => string; 
        }; 
        technicalCalculationError: {
          /* checkout.page.technicalCalculationError.title */
          /* There was a technical issue */
          title: () => string; 
          /* checkout.page.technicalCalculationError.subtitle */
          /* We're working to fix it. Please try again later. */
          subtitle: () => string; 
          /* checkout.page.technicalCalculationError.cta */
          /* Got It */
          cta: () => string; 
        }; 
        /* checkout.page.siteLogo_altTextDefault */
        /* Store logo */
        siteLogo_altTextDefault: () => string; 
      }; 
      orderFailedModal: {
        invalidCardError: {
          /* checkout.orderFailedModal.invalidCardError.title */
          /* Gift Card Code Isn’t Valid */
          title: () => string; 
          /* checkout.orderFailedModal.invalidCardError.content */
          /* Please check the code and try again. */
          content: () => string; 
        }; 
        expiredCardError: {
          /* checkout.orderFailedModal.expiredCardError.title */
          /* Your Gift Card Has Expired */
          title: () => string; 
          /* checkout.orderFailedModal.expiredCardError.content */
          /* Select a different payment method to complete this purchase. */
          content: () => string; 
        }; 
        balanceChangedError: {
          /* checkout.orderFailedModal.balanceChangedError.title */
          /* Your Gift Card Balance Has Changed */
          title: () => string; 
          /* checkout.orderFailedModal.balanceChangedError.content */
          /* Review your payment info before completing this purchase. */
          content: () => string; 
        }; 
        insufficientFundsError: {
          /* checkout.orderFailedModal.insufficientFundsError.title */
          /* Your Gift Card Balance is $0.00 */
          title: () => string; 
          /* checkout.orderFailedModal.insufficientFundsError.content */
          /* Select a different payment method to complete this purchase. */
          content: () => string; 
        }; 
        genericFallbackError: {
          /* checkout.orderFailedModal.genericFallbackError.title */
          /* Looks like there's a connection problem */
          title: () => string; 
          /* checkout.orderFailedModal.genericFallbackError.content */
          /* Please wait a few minutes and try again. */
          content: () => string; 
        }; 
        returnToCheckout: {
          /* checkout.orderFailedModal.returnToCheckout.button */
          /* Return to Checkout */
          button: () => string; 
        }; 
      }; 
      paymentDetails: {
        enterGiftCard: {
          error: {
            replaceCurrency: {
              /* checkout.paymentDetails.enterGiftCard.error.replaceCurrency.title */
              /* Change the currency to complete your order */
              title: () => string; 
              /* checkout.paymentDetails.enterGiftCard.error.replaceCurrency.content */
              /* The currency of this gift card doesn't match your cart. Change your cart's currency and try again. */
              content: () => string; 
            }; 
            /* checkout.paymentDetails.enterGiftCard.error.cardExpired */
            /* Your gift card has expired. */
            cardExpired: () => string; 
            /* checkout.paymentDetails.enterGiftCard.error.invalidCardNumber */
            /* Gift card code isn’t valid. Please check the code and try again. */
            invalidCardNumber: () => string; 
            /* checkout.paymentDetails.enterGiftCard.error.noBalance */
            /* You’ve run out of money on this gift card. */
            noBalance: () => string; 
            genericFallback: {
              /* checkout.paymentDetails.enterGiftCard.error.genericFallback */
              /* It looks like there's a connection problem. Please try again. */
              $value: () => string; 
              /* checkout.paymentDetails.enterGiftCard.error.genericFallback.title */
              /* This gift card code isn’t valid */
              title: () => string; 
              /* checkout.paymentDetails.enterGiftCard.error.genericFallback.content */
              /* To complete your purchase, go back and remove the gift card code. */
              content: () => string; 
            }; 
            /* checkout.paymentDetails.enterGiftCard.error.cardDisabled */
            /* Your gift card has been disabled. */
            cardDisabled: () => string; 
            /* checkout.paymentDetails.enterGiftCard.error.wrongCurrency */
            /* Gift card currency does not match the currency of our site. */
            wrongCurrency: () => string; 
            serviceDown: {
              /* checkout.paymentDetails.enterGiftCard.error.serviceDown */
              /* Gift card service is temporarily down. Please try again later. */
              $value: () => string; 
              /* checkout.paymentDetails.enterGiftCard.error.serviceDown.subtitle */
              /* We’re working to fix it. Please try again later. */
              subtitle: () => string; 
              /* checkout.paymentDetails.enterGiftCard.error.serviceDown.cta */
              /* Return to Checkout */
              cta: () => string; 
              /* checkout.paymentDetails.enterGiftCard.error.serviceDown.title */
              /* Gift card service is temporarily down */
              title: () => string; 
              /* checkout.paymentDetails.enterGiftCard.error.serviceDown.inline */
              /* Sorry, we can't accept gift cards right now because of a technical issue. Please continue without a gift card or try again later. */
              inline: () => string; 
            }; 
          }; 
          /* checkout.paymentDetails.enterGiftCard.placeholder */
          /* Enter your code */
          placeholder: () => string; 
          /* checkout.paymentDetails.enterGiftCard.CTA */
          /* Apply */
          CTA: () => string; 
          /* checkout.paymentDetails.enterGiftCard.remove */
          /* Remove */
          remove: () => string; 
          clearButton: {
            /* checkout.paymentDetails.enterGiftCard.clearButton.ariaLabel */
            /* Clear gift card code */
            ariaLabel: () => string; 
          }; 
        }; 
        redeemGiftCard: {
          checkbox: {
            /* checkout.paymentDetails.redeemGiftCard.checkbox.label */
            /* Redeem a gift card */
            label: () => string; 
          }; 
          cantPurchaseGC: {
            /* checkout.paymentDetails.redeemGiftCard.cantPurchaseGC.tooltip */
            /* Gift cards can't be used to purchase other gift cards. */
            tooltip: () => string; 
          }; 
        }; 
        enterPromoCode: {
          /* checkout.paymentDetails.enterPromoCode.placeholder */
          /* e.g., SAVE50 */
          placeholder: () => string; 
          clearButton: {
            /* checkout.paymentDetails.enterPromoCode.clearButton.ariaLabel */
            /* Clear promo code */
            ariaLabel: () => string; 
          }; 
        }; 
        applyCoupon: {
          cantPurchaseGC: {
            /* checkout.paymentDetails.applyCoupon.cantPurchaseGC.tooltip */
            /* Coupons can’t be applied when purchasing a gift card. */
            tooltip: () => string; 
          }; 
        }; 
      }; 
      minimumOrderModal: {
        withoutPickup: {
          /* checkout.minimumOrderModal.withoutPickup.title */
          /* We only deliver orders over {{minAmount}} */
          title: (data: Record<'minAmount', unknown>) => string; 
          /* checkout.minimumOrderModal.withoutPickup.body */
          /* Add additional items worth {{additionalAmount}} or more to complete checkout. */
          body: (data: Record<'additionalAmount', unknown>) => string; 
          /* checkout.minimumOrderModal.withoutPickup.continueShoppingCTA */
          /* Continue Browsing */
          continueShoppingCTA: () => string; 
        }; 
        withPickup: {
          /* checkout.minimumOrderModal.withPickup.title */
          /* We only deliver orders over {{minAmount}} */
          title: (data: Record<'minAmount', unknown>) => string; 
          /* checkout.minimumOrderModal.withPickup.body */
          /* Choose pickup or add additional items worth at least {{additionalAmount}} to complete checkout. */
          body: (data: Record<'additionalAmount', unknown>) => string; 
          /* checkout.minimumOrderModal.withPickup.gotItCTA */
          /* Got It */
          gotItCTA: () => string; 
        }; 
        delivery: {
          withPickup: {
            /* checkout.minimumOrderModal.delivery.withPickup.title */
            /* We only deliver orders over {{minAmount}} */
            title: (data: Record<'minAmount', unknown>) => string; 
          }; 
          withoutPickup: {
            /* checkout.minimumOrderModal.delivery.withoutPickup.title */
            /* We only deliver orders over {{minAmount}} */
            title: (data: Record<'minAmount', unknown>) => string; 
          }; 
        }; 
      }; 
      informative_modal: {
        /* checkout.informative_modal.return_to_cart */
        /* Return to Cart */
        return_to_cart: () => string; 
        /* checkout.informative_modal.cashier_internal_error */
        /* Please refresh the page and try again. */
        cashier_internal_error: () => string; 
        /* checkout.informative_modal.close_button */
        /* Close */
        close_button: () => string; 
        /* checkout.informative_modal.unsupported_shipping_destination */
        /* Please enter a different address to complete your purchase. */
        unsupported_shipping_destination: () => string; 
        /* checkout.informative_modal.cashier_internal_error_title */
        /* Looks like there's a connection problem */
        cashier_internal_error_title: () => string; 
        /* checkout.informative_modal.unsupported_shipping_destination_title */
        /* Our items are not currently available in your region */
        unsupported_shipping_destination_title: () => string; 
        /* checkout.informative_modal.wallet_payment_problem */
        /* There was a problem with your payment */
        wallet_payment_problem: () => string; 
      }; 
      orderSummary: {
        totals: {
          pickup: {
            /* checkout.orderSummary.totals.pickup.label */
            /* Pickup */
            label: () => string; 
          }; 
        }; 
        secureCheckout: {
          /* checkout.orderSummary.secureCheckout.label */
          /* Secure Checkout */
          label: () => string; 
        }; 
        amountDueNow: {
          /* checkout.orderSummary.amountDueNow.label */
          /* Pay Now */
          label: () => string; 
        }; 
        amountDueLater: {
          /* checkout.orderSummary.amountDueLater.label */
          /* Pay Later */
          label: () => string; 
        }; 
        /* checkout.orderSummary.itemAmount_ariaLabel */
        /* Number of items {{itemNumber}} */
        itemAmount_ariaLabel: (data: Record<'itemNumber', unknown>) => string; 
        price: {
          /* checkout.orderSummary.price.ariaLabel */
          /* Price {{price}} */
          ariaLabel: (data: Record<'price', unknown>) => string; 
        }; 
        regularPrice: {
          /* checkout.orderSummary.regularPrice.ariaLabel */
          /* Regular price {{price}} */
          ariaLabel: (data: Record<'price', unknown>) => string; 
        }; 
        fullSection: {
          /* checkout.orderSummary.fullSection.ariaLabel */
          /* Items */
          ariaLabel: () => string; 
        }; 
        singleItem: {
          /* checkout.orderSummary.singleItem.ariaLabel */
          /* Item */
          ariaLabel: () => string; 
        }; 
        salePrice: {
          /* checkout.orderSummary.salePrice.ariaLabel */
          /* Sale price {{price}} */
          ariaLabel: (data: Record<'price', unknown>) => string; 
        }; 
      }; 
      order_summary: {
        items: {
          /* checkout.order_summary.items.quantity */
          /* Qty */
          quantity: () => string; 
          /* checkout.order_summary.items.more_details */
          /* More Details */
          more_details: () => string; 
          /* checkout.order_summary.items.lessDetails */
          /* Less Details */
          lessDetails: () => string; 
          /* checkout.order_summary.items.sku */
          /* SKU */
          sku: () => string; 
          /* checkout.order_summary.items.color */
          /* Color */
          color: () => string; 
          /* checkout.order_summary.items.size */
          /* Size */
          size: () => string; 
        }; 
        /* checkout.order_summary.item_out_of_stock_error */
        /* This item is no longer available. Please remove it to complete your order. */
        item_out_of_stock_error: () => string; 
        totals: {
          /* checkout.order_summary.totals.subtotal */
          /* Subtotal */
          subtotal: () => string; 
          /* checkout.order_summary.totals.items */
          /* Items */
          items: () => string; 
          /* checkout.order_summary.totals.shipping */
          /* Delivery */
          shipping: () => string; 
          /* checkout.order_summary.totals.free */
          /* Free */
          free: () => string; 
          discount: {
            /* checkout.order_summary.totals.discount */
            /* Promo Code: {{couponCode}} */
            $value: (data: Record<'couponCode', unknown>) => string; 
            /* checkout.order_summary.totals.discount.free_shipping */
            /* Free delivery */
            free_shipping: () => string; 
            /* checkout.order_summary.totals.discount.free_delivery */
            /* Free delivery */
            free_delivery: () => string; 
          }; 
          /* checkout.order_summary.totals.giftcard */
          /* Gift Card */
          giftcard: () => string; 
          /* checkout.order_summary.totals.tax */
          /* Tax */
          tax: () => string; 
          /* checkout.order_summary.totals.total */
          /* Total */
          total: () => string; 
          /* checkout.order_summary.totals.delivery */
          /* Delivery */
          delivery: () => string; 
        }; 
        /* checkout.order_summary */
        /* Order summary */
        $value: () => string; 
        removeFromCartError: {
          /* checkout.order_summary.removeFromCartError.cta */
          /* Remove */
          cta: () => string; 
        }; 
        removeItem: {
          /* checkout.order_summary.removeItem.cta */
          /* Remove Item */
          cta: () => string; 
        }; 
      }; 
      /* checkout.additionalFees */
      /* Additional fees:  */
      additionalFees: () => string; 
      additionalInfo: {
        custom_field: {
          /* checkout.additionalInfo.custom_field.apply */
          /* Apply */
          apply: () => string; 
        }; 
        missing_error: {
          /* checkout.additionalInfo.missing_error.required */
          /* Required field */
          required: () => string; 
        }; 
      }; 
      footer: {
        /* checkout.footer.terms_and_condition_button_text */
        /* Terms & Conditions */
        terms_and_condition_button_text: () => string; 
        /* checkout.footer.privacy_policy_button_text */
        /* Privacy Policy */
        privacy_policy_button_text: () => string; 
        /* checkout.footer.digital_item_policy_button_text */
        /* Digital Item Policy */
        digital_item_policy_button_text: () => string; 
        /* checkout.footer.refund_cancellation_button_text */
        /* Return Policy */
        refund_cancellation_button_text: () => string; 
        /* checkout.footer.contact_us_button_text */
        /* Contact Us */
        contact_us_button_text: () => string; 
        /* checkout.footer.payment_policy_button_text */
        /* Payment Policy */
        payment_policy_button_text: () => string; 
      }; 
      terms_and_condition_modal: {
        /* checkout.terms_and_condition_modal.title */
        /* Terms and Conditions */
        title: () => string; 
      }; 
      privacy_policy_modal: {
        /* checkout.privacy_policy_modal.title */
        /* Privacy Policy */
        title: () => string; 
      }; 
      refund_cancellation_modal: {
        /* checkout.refund_cancellation_modal.title */
        /* Return Policy */
        title: () => string; 
      }; 
      contact_us_modal: {
        /* checkout.contact_us_modal.title */
        /* Contact Us */
        title: () => string; 
      }; 
      /* checkout.continue_shopping */
      /* Continue Browsing */
      continue_shopping: () => string; 
      address_information: {
        zip_validation_errors: {
          /* checkout.address_information.zip_validation_errors.invalid */
          /* Enter a valid zip / postal code. */
          invalid: () => string; 
        }; 
        /* checkout.address_information.first_name_label */
        /* First name */
        first_name_label: () => string; 
        /* checkout.address_information.last_name_label */
        /* Last name */
        last_name_label: () => string; 
        /* checkout.address_information.company_label */
        /* Company Name */
        company_label: () => string; 
        /* checkout.address_information.phone_number_label */
        /* Phone */
        phone_number_label: () => string; 
        /* checkout.address_information.email_label */
        /* Email for order confirmation */
        email_label: () => string; 
        error_message: {
          /* checkout.address_information.error_message.missing_info */
          /* Some of your info is missing. */
          missing_info: () => string; 
          /* checkout.address_information.error_message.suffix */
          /* <1>Edit</1> or <3>add</3> a new address. */
          suffix: () => string; 
        }; 
      }; 
      /* checkout.edit_cart */
      /* Edit Cart */
      edit_cart: () => string; 
      deliveryMethod: {
        shipping: {
          /* checkout.deliveryMethod.shipping.label */
          /* Shipping & delivery */
          label: () => string; 
        }; 
        pickup: {
          /* checkout.deliveryMethod.pickup.label */
          /* Pickup */
          label: () => string; 
        }; 
        selectOption: {
          /* checkout.deliveryMethod.selectOption.lable */
          /* Select an option */
          lable: () => string; 
        }; 
        error: {
          /* checkout.deliveryMethod.error.unsupportedRegion */
          /* Sorry, our items are not currently available in your region. */
          unsupportedRegion: () => string; 
          /* checkout.deliveryMethod.error.missingZipCode */
          /* You're missing a zip / postal code. Go back to your delivery details to add it. */
          missingZipCode: () => string; 
        }; 
        shippingAndDelivery: {
          /* checkout.deliveryMethod.shippingAndDelivery.label */
          /* Shipping & delivery */
          label: () => string; 
        }; 
        date: {
          /* checkout.deliveryMethod.date.label */
          /* Date* */
          label: () => string; 
        }; 
        time: {
          /* checkout.deliveryMethod.time.label */
          /* Time* */
          label: () => string; 
        }; 
        shippingWithDeliveryTime: {
          /* checkout.deliveryMethod.shippingWithDeliveryTime.dropdownOption */
          /* {{time}} ({{price}}) */
          dropdownOption: (data: Record<'time' | 'price', unknown>) => string; 
        }; 
        pickupFromPrice: {
          /* checkout.deliveryMethod.pickupFromPrice.label */
          /* From {{price}} */
          label: (data: Record<'price', unknown>) => string; 
        }; 
        pickupWillBeReady: {
          /* checkout.deliveryMethod.pickupWillBeReady.title */
          /* Your order will be ready */
          title: () => string; 
        }; 
        checkbox: {
          /* checkout.deliveryMethod.checkbox.true */
          /* Yes */
          true: () => string; 
          /* checkout.deliveryMethod.checkbox.false */
          /* No */
          false: () => string; 
        }; 
        deliveryInstructions: {
          /* checkout.deliveryMethod.deliveryInstructions.title */
          /* Delivery instructions */
          title: () => string; 
        }; 
        deliveryProfiles: {
          availableForAllItems: {
            /* checkout.deliveryMethod.deliveryProfiles.availableForAllItems.badge */
            /* Available for all items */
            badge: () => string; 
          }; 
        }; 
      }; 
      delivery_method: {
        /* checkout.delivery_method.pickup_address */
        /* {{addressLine}}, {{city}}, {{subdivision}}, {{zipCode}}, {{country}} */
        pickup_address: (data: Record<'addressLine' | 'city' | 'subdivision' | 'zipCode' | 'country', unknown>) => string; 
        /* checkout.delivery_method.free_label */
        /* Free */
        free_label: () => string; 
      }; 
      pickup_details_form: {
        /* checkout.pickup_details_form.store_pickup_address_header */
        /* Pickup address */
        store_pickup_address_header: () => string; 
        /* checkout.pickup_details_form.store_pickup_address_header2 */
        /* Pickup availability */
        store_pickup_address_header2: () => string; 
        /* checkout.pickup_details_form.store_pickup_instructions_header */
        /* Pickup instructions */
        store_pickup_instructions_header: () => string; 
      }; 
      shipping_details: {
        /* checkout.shipping_details.delivery_address2 */
        /* {{addressLine1}}, {{addressLine2}}
        {{city}}, {{subdivision}} {{zipCode}}, {{country}} */
        delivery_address2: (data: Record<'addressLine1' | 'addressLine2' | 'city' | 'subdivision' | 'zipCode' | 'country', unknown>) => string; 
        /* checkout.shipping_details.delivery_address2_without_subdivision */
        /* {{addressLine1}}, {{addressLine2}}
        {{city}} {{zipCode}}, {{country}} */
        delivery_address2_without_subdivision: (data: Record<'addressLine1' | 'addressLine2' | 'city' | 'zipCode' | 'country', unknown>) => string; 
      }; 
      summary: {
        shipping: {
          /* checkout.summary.shipping.noAmount */
          /* -- */
          noAmount: () => string; 
        }; 
      }; 
      terms_and_conditions: {
        checkbox: {
          /* checkout.terms_and_conditions.checkbox.privacy_policy */
          /* <0>Privacy Policy</0> */
          privacy_policy: () => string; 
          /* checkout.terms_and_conditions.checkbox.label */
          /* I agree to the <0/> */
          label: () => string; 
          /* checkout.terms_and_conditions.checkbox.digital_item_policy */
          /* <0>Digital Item Policy</0> */
          digital_item_policy: () => string; 
          /* checkout.terms_and_conditions.checkbox.add_item_to_list */
          /* <0/>, <1/> */
          add_item_to_list: () => string; 
          /* checkout.terms_and_conditions.checkbox.add_last_item */
          /* <0/> and <1/> */
          add_last_item: () => string; 
          /* checkout.terms_and_conditions.checkbox.return_policy */
          /* <0>Return Policy</0> */
          return_policy: () => string; 
          /* checkout.terms_and_conditions.checkbox.terms_and_conditions */
          /* <0>Terms & Conditions</0> */
          terms_and_conditions: () => string; 
          /* checkout.terms_and_conditions.checkbox.item_policy_backup_text */
          /* Item Policy */
          item_policy_backup_text: () => string; 
        }; 
      }; 
      subscription: {
        /* checkout.subscription.checkbox */
        /* I agree to receive marketing communications via email and/or SMS to any emails and phone numbers added above */
        checkbox: () => string; 
        new: {
          /* checkout.subscription.new.checkbox */
          /* I agree to receive news to the email address and/or phone numbers added */
          checkbox: () => string; 
        }; 
      }; 
      digital_item_policy_modal: {
        /* checkout.digital_item_policy_modal.title */
        /* Digital Item Policy */
        title: () => string; 
      }; 
      coupon: {
        /* checkout.coupon.label */
        /* Enter a promo code */
        label: () => string; 
        /* checkout.coupon.applyButton */
        /* Apply */
        applyButton: () => string; 
        /* checkout.coupon.removeButton */
        /* Remove */
        removeButton: () => string; 
        invalidGeneral: {
          /* checkout.coupon.invalidGeneral.error */
          /* This promo code isn't valid. */
          error: () => string; 
        }; 
        notActiveYet: {
          /* checkout.coupon.notActiveYet.error */
          /* This promotion hasn't started yet. */
          error: () => string; 
        }; 
        expired: {
          /* checkout.coupon.expired.error */
          /* This promotion has ended. */
          error: () => string; 
        }; 
        maxUsage: {
          /* checkout.coupon.maxUsage.error */
          /* This promo code has exceeded the number of times it can be used. */
          error: () => string; 
        }; 
        disabled: {
          /* checkout.coupon.disabled.error */
          /* This promo code isn't valid. */
          error: () => string; 
        }; 
        characterLimit: {
          /* checkout.coupon.characterLimit.error */
          /* Your code can't have more than 20 characters. */
          error: () => string; 
        }; 
        invalidProducts: {
          /* checkout.coupon.invalidProducts.error */
          /* This promo code can only be used for specific products. */
          error: () => string; 
        }; 
        invalidSubtotal: {
          /* checkout.coupon.invalidSubtotal.error */
          /* This promo code can only be used for orders above {{subtotal}}. */
          error: (data: Record<'subtotal', unknown>) => string; 
        }; 
        productQuantity: {
          /* checkout.coupon.productQuantity.error */
          /* This promotion only applies for purchases over {{quantity}} items. */
          error: (data: Record<'quantity', unknown>) => string; 
        }; 
        alreadyUsed: {
          /* checkout.coupon.alreadyUsed.error */
          /* You already used this promo code. */
          error: () => string; 
        }; 
        subscriptions: {
          /* checkout.coupon.subscriptions.error */
          /* This promo code isn’t valid for subscriptions. */
          error: () => string; 
        }; 
        inputCode: {
          /* checkout.coupon.inputCode.ariaLabel */
          /* Input your promo code */
          ariaLabel: () => string; 
        }; 
      }; 
      terms_and_condition: {
        /* checkout.terms_and_condition.checkbox_error */
        /* Please indicate that you’ve read and agree to the site's policies. */
        checkbox_error: () => string; 
      }; 
      myCartPaymentMethod: {
        offlinePlan: {
          /* checkout.myCartPaymentMethod.offlinePlan.lineItemWithId */
          /* Pay later with {{membership}} */
          lineItemWithId: (data: Record<'membership', unknown>) => string; 
          /* checkout.myCartPaymentMethod.offlinePlan.lineItemWithoutId */
          /* Pay later with a plan */
          lineItemWithoutId: () => string; 
        }; 
        offlinePayment: {
          /* checkout.myCartPaymentMethod.offlinePayment.lineItem */
          /* Pay in person */
          lineItem: () => string; 
        }; 
        deposit: {
          /* checkout.myCartPaymentMethod.deposit.lineItem */
          /* Deposit: {{price}} */
          lineItem: (data: Record<'price', unknown>) => string; 
        }; 
        onlinePlan: {
          /* checkout.myCartPaymentMethod.onlinePlan.lineItem */
          /* Pay with {{membership}} */
          lineItem: (data: Record<'membership', unknown>) => string; 
        }; 
        payWithPlan: {
          /* checkout.myCartPaymentMethod.payWithPlan.lineItem */
          /* Pay with a plan */
          lineItem: () => string; 
        }; 
      }; 
      orderTotal: {
        fullSection: {
          /* checkout.orderTotal.fullSection.ariaLabel */
          /* Total due breakdown */
          ariaLabel: () => string; 
        }; 
        noShipping: {
          /* checkout.orderTotal.noShipping.ariaLabel */
          /* Not available */
          ariaLabel: () => string; 
        }; 
      }; 
      /* checkout.page_plan_autorenew */
      /* Auto-renew until canceled */
      page_plan_autorenew: () => string; 
      /* checkout.page_plan_months */
      /* month */
      page_plan_months: () => string; 
      /* checkout.page_plan_years */
      /* year */
      page_plan_years: () => string; 
      /* checkout.page_plan_month */
      /* month */
      page_plan_month: () => string; 
      /* checkout.page_plan_weeks */
      /* week */
      page_plan_weeks: () => string; 
      /* checkout.page_plan_week */
      /* week */
      page_plan_week: () => string; 
      /* checkout.page_plan_year */
      /* year */
      page_plan_year: () => string; 
      /* checkout.page_plan_duration_description_singular */
      /* {{duration}} {{frequencyUnitSingular}} subscription */
      page_plan_duration_description_singular: (data: Record<'duration' | 'frequencyUnitSingular', unknown>) => string; 
      /* checkout.page_plan_duration_description_plural */
      /* {{numberOfFrequencyUnits}} {{frequencyUnitSingular}} subscription */
      page_plan_duration_description_plural: (data: Record<'numberOfFrequencyUnits' | 'frequencyUnitSingular', unknown>) => string; 
      /* checkout.page_plan_day */
      /* day */
      page_plan_day: () => string; 
      /* checkout.page_plan_days */
      /* days */
      page_plan_days: () => string; 
      /* checkout.page_plan_frequency_description_days_plural_label */
      /* every {{numberOfFrequencyUnits}} days */
      page_plan_frequency_description_days_plural_label: (data: Record<'numberOfFrequencyUnits', unknown>) => string; 
      /* checkout.page_plan_frequency_description_weeks_plural_label */
      /* every {{numberOfFrequencyUnits}} weeks */
      page_plan_frequency_description_weeks_plural_label: (data: Record<'numberOfFrequencyUnits', unknown>) => string; 
      /* checkout.page_plan_frequency_description_months_plural_label */
      /* every {{numberOfFrequencyUnits}} months */
      page_plan_frequency_description_months_plural_label: (data: Record<'numberOfFrequencyUnits', unknown>) => string; 
      /* checkout.page_plan_frequency_description_years_plural_label */
      /* every {{numberOfFrequencyUnits}} years */
      page_plan_frequency_description_years_plural_label: (data: Record<'numberOfFrequencyUnits', unknown>) => string; 
      /* checkout.page_plan_frequency_description_2_days_label */
      /* every 2 days */
      page_plan_frequency_description_2_days_label: () => string; 
      /* checkout.page_plan_frequency_description_2_weeks_label */
      /* every 2 weeks */
      page_plan_frequency_description_2_weeks_label: () => string; 
      /* checkout.page_plan_frequency_description_2_months_label */
      /* every 2 months */
      page_plan_frequency_description_2_months_label: () => string; 
      /* checkout.page_plan_frequency_description_2_years_label */
      /* every 2 years */
      page_plan_frequency_description_2_years_label: () => string; 
      /* checkout.page_plan_frequency_description_day_singular_label */
      /* per day */
      page_plan_frequency_description_day_singular_label: () => string; 
      /* checkout.page_plan_frequency_description_week_singular_label */
      /* per week */
      page_plan_frequency_description_week_singular_label: () => string; 
      /* checkout.page_plan_frequency_description_month_singular_label */
      /* per month */
      page_plan_frequency_description_month_singular_label: () => string; 
      /* checkout.page_plan_frequency_description_year_singular_label */
      /* per year */
      page_plan_frequency_description_year_singular_label: () => string; 
      promoCodeError: {
        invalidSubtotal: {
          /* checkout.promoCodeError.invalidSubtotal.title */
          /* This promo code can only be used for orders above {{subtotal}} */
          title: (data: Record<'subtotal', unknown>) => string; 
          /* checkout.promoCodeError.invalidSubtotal.cta */
          /* Continue Browsing */
          cta: () => string; 
          /* checkout.promoCodeError.invalidSubtotal.subtitle */
          /* To complete your purchase, add additional items to your cart or remove the promo code. */
          subtitle: () => string; 
        }; 
        notActiveYet: {
          /* checkout.promoCodeError.notActiveYet.cta */
          /* Go Back to Checkout */
          cta: () => string; 
          /* checkout.promoCodeError.notActiveYet.title */
          /* This promo code can’t be used yet */
          title: () => string; 
          /* checkout.promoCodeError.notActiveYet.subtitle */
          /* This promotion hasn’t started yet. If you want to complete your purchase now, go back and remove the promo code. */
          subtitle: () => string; 
        }; 
        subscriptionsNotValid: {
          /* checkout.promoCodeError.subscriptionsNotValid.subtitle */
          /* To complete your purchase, go back and remove the promo code. */
          subtitle: () => string; 
          /* checkout.promoCodeError.subscriptionsNotValid.cta */
          /* Go Back to Checkout */
          cta: () => string; 
          /* checkout.promoCodeError.subscriptionsNotValid.title */
          /* This promo code can’t be used for subscriptions */
          title: () => string; 
        }; 
        expired: {
          /* checkout.promoCodeError.expired.subtitle */
          /* This promotion has ended. To complete your purchase, go back and remove the promo code. */
          subtitle: () => string; 
          /* checkout.promoCodeError.expired.cta */
          /* Go Back to Checkout */
          cta: () => string; 
          /* checkout.promoCodeError.expired.title */
          /* This promo code can’t be used anymore */
          title: () => string; 
        }; 
        invalidProductQuantity: {
          /* checkout.promoCodeError.invalidProductQuantity.cta */
          /* Continue Browsing */
          cta: () => string; 
          /* checkout.promoCodeError.invalidProductQuantity.subtitle */
          /* This promotion only applies for purchases over {{quantity}} items. To complete your purchase, add more items to your cart or remove the promo code. */
          subtitle: (data: Record<'quantity', unknown>) => string; 
          /* checkout.promoCodeError.invalidProductQuantity.title */
          /* This promo code can’t be applied */
          title: () => string; 
        }; 
        invalidProducts: {
          /* checkout.promoCodeError.invalidProducts.title */
          /* This promo code can only be used for specific items */
          title: () => string; 
          /* checkout.promoCodeError.invalidProducts.subtitle */
          /* To complete your purchase, add items that are part of this promotion or remove the promo code. */
          subtitle: () => string; 
          /* checkout.promoCodeError.invalidProducts.cta */
          /* Continue Browsing */
          cta: () => string; 
        }; 
        doesntExist: {
          /* checkout.promoCodeError.doesntExist.title */
          /* This promo code isn't valid */
          title: () => string; 
          /* checkout.promoCodeError.doesntExist.subtitle */
          /* To complete your purchase, check your code again or remove it from your cart. */
          subtitle: () => string; 
          /* checkout.promoCodeError.doesntExist.cta */
          /* Go Back to Checkout */
          cta: () => string; 
        }; 
        exceededUsage: {
          /* checkout.promoCodeError.exceededUsage.title */
          /* This promo code can’t be used anymore */
          title: () => string; 
          /* checkout.promoCodeError.exceededUsage.cta */
          /* Go Back to Checkout */
          cta: () => string; 
          /* checkout.promoCodeError.exceededUsage.subtitle */
          /* This promo code has exceeded the number of times it can be used. To complete your purchase, go back and remove it. */
          subtitle: () => string; 
        }; 
        limitedUsage: {
          /* checkout.promoCodeError.limitedUsage.title */
          /* You’ve already used this promo code */
          title: () => string; 
          /* checkout.promoCodeError.limitedUsage.cta */
          /* Go Back to Checkout */
          cta: () => string; 
          /* checkout.promoCodeError.limitedUsage.subtitle */
          /* This coupon can only be used one time per customer. To complete your purchase, remove the promo code. */
          subtitle: () => string; 
        }; 
        disabled: {
          /* checkout.promoCodeError.disabled.subtitle */
          /* To complete your purchase, go back and remove the promo code. */
          subtitle: () => string; 
          /* checkout.promoCodeError.disabled.title */
          /* This promo code isn't valid */
          title: () => string; 
          /* checkout.promoCodeError.disabled.cta */
          /* Go Back to Checkout */
          cta: () => string; 
        }; 
        subscriptionWithZeroTotal: {
          /* checkout.promoCodeError.subscriptionWithZeroTotal.title */
          /* This promo code can't be used for subscriptions */
          title: () => string; 
          /* checkout.promoCodeError.subscriptionWithZeroTotal.subtitle */
          /* To complete your purchase, go back and remove the promo code. */
          subtitle: () => string; 
          /* checkout.promoCodeError.subscriptionWithZeroTotal.cta */
          /* Go Back to Checkout */
          cta: () => string; 
        }; 
        cantPurchaseGC: {
          /* checkout.promoCodeError.cantPurchaseGC.label */
          /* Coupons can’t be applied when purchasing a gift card. */
          label: () => string; 
        }; 
      }; 
      bookingsError: {
        insufficientPlanSessions: {
          /* checkout.bookingsError.insufficientPlanSessions.title */
          /* Your plan doesn’t have enough sessions left */
          title: () => string; 
          /* checkout.bookingsError.insufficientPlanSessions.subtitle */
          /* To complete your order, check how many sessions you have left and remove the extra sessions. Once you’ve finished the remaining sessions for this plan, you can purchase a new plan or use a renewed plan. */
          subtitle: () => string; 
          /* checkout.bookingsError.insufficientPlanSessions.cta */
          /* Go Back to Cart */
          cta: () => string; 
        }; 
      }; 
      membersInfoBox: {
        /* checkout.membersInfoBox.login */
        /* Have an account? <1>Log in</1> */
        login: () => string; 
        /* checkout.membersInfoBox.loggedInMessage */
        /* Logged in as {{email}} */
        loggedInMessage: (data: Record<'email', unknown>) => string; 
        /* checkout.membersInfoBox.logout */
        /* Log out */
        logout: () => string; 
      }; 
      /* checkout.customer_details */
      /* Customer details */
      customer_details: () => string; 
      /* checkout.shipping_details_title */
      /* Delivery details */
      shipping_details_title: () => string; 
      /* checkout.payment */
      /* Payment */
      payment: () => string; 
      /* checkout.continue_button_label */
      /* Continue */
      continue_button_label: () => string; 
      /* checkout.edit */
      /* Edit */
      edit: () => string; 
      /* checkout.change */
      /* Change */
      change: () => string; 
      billing_information: {
        /* checkout.billing_information.title */
        /* Billing address */
        title: () => string; 
        /* checkout.billing_information.billing_same_as_shipping_label */
        /* Same as delivery address */
        billing_same_as_shipping_label: () => string; 
        /* checkout.billing_information.billing_same_as_delivery_label */
        /* Same as delivery address */
        billing_same_as_delivery_label: () => string; 
      }; 
      customerName: {
        /* checkout.customerName.label */
        /* {{firstName}} {{lastName}} */
        label: (data: Record<'firstName' | 'lastName', unknown>) => string; 
      }; 
      extended_fields_view: {
        checkbox: {
          /* checkout.extended_fields_view.checkbox.true */
          /* Yes */
          true: () => string; 
          /* checkout.extended_fields_view.checkbox.false */
          /* No */
          false: () => string; 
        }; 
      }; 
      collapsible_section: {
        /* checkout.collapsible_section.more_details */
        /* More Details */
        more_details: () => string; 
        /* checkout.collapsible_section.less_details */
        /* Less Details */
        less_details: () => string; 
      }; 
      logOutModal: {
        /* checkout.logOutModal.title */
        /* Log out of site? */
        title: () => string; 
        /* checkout.logOutModal.body */
        /* Your info and items will be available when you log back in to {{memberEmail}}. */
        body: (data: Record<'memberEmail', unknown>) => string; 
        /* checkout.logOutModal.mainButton */
        /* Log Out */
        mainButton: () => string; 
        /* checkout.logOutModal.secondaryButton */
        /* Return to Checkout */
        secondaryButton: () => string; 
      }; 
      member: {
        addressDropdown: {
          addNewAddress: {
            /* checkout.member.addressDropdown.addNewAddress.cta */
            /* Add a New Address */
            cta: () => string; 
          }; 
        }; 
      }; 
      cancel: {
        /* checkout.cancel.cta */
        /* Cancel */
        cta: () => string; 
      }; 
      saveAndContinue: {
        /* checkout.saveAndContinue.cta */
        /* Save & Continue */
        cta: () => string; 
      }; 
      chooseNewAddress: {
        /* checkout.chooseNewAddress.cta */
        /* Use a Different Address */
        cta: () => string; 
      }; 
      saveAddress: {
        /* checkout.saveAddress.checkbox */
        /* Save this address */
        checkbox: () => string; 
      }; 
      defaultAddress: {
        /* checkout.defaultAddress.checkbox */
        /* Make this my default address */
        checkbox: () => string; 
      }; 
      errorModal: {
        validationsSPI: {
          /* checkout.errorModal.validationsSPI.title */
          /* This order can’t be placed */
          title: () => string; 
          /* checkout.errorModal.validationsSPI.subtitle */
          /* Please review your order details and try again. */
          subtitle: () => string; 
          /* checkout.errorModal.validationsSPI.cta */
          /* Close */
          cta: () => string; 
        }; 
        totalPriceChanged: {
          /* checkout.errorModal.totalPriceChanged.title */
          /* Your order total was updated */
          title: () => string; 
          /* checkout.errorModal.totalPriceChanged.subtitle */
          /* This could be because of a pricing change, updated delivery rates or a promotion that ended. Refresh the page to see your updated total or contact us for more details. */
          subtitle: () => string; 
          /* checkout.errorModal.totalPriceChanged.cta */
          /* Got It */
          cta: () => string; 
        }; 
      }; 
      /* checkout.delivery_details_title */
      /* Delivery details */
      delivery_details_title: () => string; 
      deliveryMethodOptions: {
        enterAddress: {
          /* checkout.deliveryMethodOptions.enterAddress.sectionNotification */
          /* To check for more delivery options, <1>enter an address</1>. */
          sectionNotification: () => string; 
        }; 
      }; 
      placeOrderPay: {
        /* checkout.placeOrderPay.button */
        /* Place Order & Pay */
        button: () => string; 
      }; 
      /* checkout.customer_delivery_details_title */
      /* Customer & delivery details */
      customer_delivery_details_title: () => string; 
      mobile: {
        showOrderSummary: {
          /* checkout.mobile.showOrderSummary.label */
          /* Show summary */
          label: () => string; 
        }; 
        hideOrderSummary: {
          /* checkout.mobile.hideOrderSummary.label */
          /* Hide summary */
          label: () => string; 
        }; 
        showItems: {
          /* checkout.mobile.showItems.label */
          /* Show items ({{number}}) */
          label: (data: Record<'number', unknown>) => string; 
        }; 
        hideItems: {
          /* checkout.mobile.hideItems.label */
          /* Hide items ({{number}}) */
          label: (data: Record<'number', unknown>) => string; 
        }; 
        alertIcon: {
          /* checkout.mobile.alertIcon.ariaLabel */
          /* There is an issue with your order. Review the order summary */
          ariaLabel: () => string; 
        }; 
      }; 
      billingAddress: {
        save: {
          /* checkout.billingAddress.save.cta */
          /* Save Billing Address */
          cta: () => string; 
        }; 
      }; 
      donations: {
        address_information: {
          /* checkout.donations.address_information.donor_email_label */
          /* Email for donation confirmation */
          donor_email_label: () => string; 
        }; 
        review_donation: {
          /* checkout.donations.review_donation.description */
          /* Check the donation details above, and complete your donation when you're ready. */
          description: () => string; 
          /* checkout.donations.review_donation.title */
          /* Review your donation */
          title: () => string; 
        }; 
        donateNow: {
          /* checkout.donations.donateNow.button */
          /* Donate Now */
          button: () => string; 
        }; 
        /* checkout.donations.donor_details */
        /* Donor details */
        donor_details: () => string; 
        /* checkout.donations.donation_summary */
        /* Your donation */
        donation_summary: () => string; 
      }; 
      payment_policy_modal: {
        /* checkout.payment_policy_modal.title */
        /* Payment Policy */
        title: () => string; 
      }; 
      policies: {
        checkbox: {
          /* checkout.policies.checkbox.payment_policy */
          /* <0>Payment Policy</0> which may include cancellation fees */
          payment_policy: () => string; 
          /* checkout.policies.checkbox.custom_policy */
          /* <0>{{customPolicyTitle}}</0> */
          custom_policy: (data: Record<'customPolicyTitle', unknown>) => string; 
          /* checkout.policies.checkbox.item_policy */
          /* <0>{{itemPolicyTitle}}</0> */
          item_policy: (data: Record<'itemPolicyTitle', unknown>) => string; 
        }; 
      }; 
      payment_policy: {
        /* checkout.payment_policy.checkbox_error */
        /* Please indicate that you’ve read and agree to the payment policies. */
        checkbox_error: () => string; 
        checkbox: {
          /* checkout.payment_policy.checkbox.label */
          /* I agree to the <0/> */
          label: () => string; 
        }; 
      }; 
      payLinks: {
        thankYouPage: {
          /* checkout.payLinks.thankYouPage.orderNumber */
          /* Order number: {{incrementalID}} */
          orderNumber: (data: Record<'incrementalID', unknown>) => string; 
          /* checkout.payLinks.thankYouPage.amountPaid */
          /* Amount paid: {{amountPaid}} */
          amountPaid: (data: Record<'amountPaid', unknown>) => string; 
          /* checkout.payLinks.thankYouPage.subtitle */
          /* You’ll receive a confirmation email when the payment is processed. */
          subtitle: () => string; 
          /* checkout.payLinks.thankYouPage.title */
          /* Thank you for your payment */
          title: () => string; 
        }; 
        generalError: {
          /* checkout.payLinks.generalError.title */
          /* Couldn’t open the link */
          title: () => string; 
          /* checkout.payLinks.generalError.subtitle */
          /* We’re experiencing a technical issue on our end. Refresh the page and try again. */
          subtitle: () => string; 
        }; 
        linkExpired: {
          /* checkout.payLinks.linkExpired.title */
          /* This pay link has expired */
          title: () => string; 
          /* checkout.payLinks.linkExpired.subtitle */
          /* If there is a mistake or for further assistance, please contact us. */
          subtitle: () => string; 
        }; 
        linkNotFound: {
          /* checkout.payLinks.linkNotFound.subtitle */
          /* Refresh your page to try again. If there is still an issue, please contact us. */
          subtitle: () => string; 
          /* checkout.payLinks.linkNotFound.title */
          /* Pay link not found */
          title: () => string; 
        }; 
        /* checkout.payLinks.mainCta */
        /* Pay Now */
        mainCta: () => string; 
        paylinkValid: {
          /* checkout.payLinks.paylinkValid.disclaimer */
          /* This pay link is valid until {{expirationDate}}. */
          disclaimer: (data: Record<'expirationDate', unknown>) => string; 
        }; 
        couldntProcessPayment: {
          /* checkout.payLinks.couldntProcessPayment.title */
          /* We couldn’t process your payment */
          title: () => string; 
          /* checkout.payLinks.couldntProcessPayment.subtitle */
          /* There was an issue with the payment provider. Try again or select a different payment method. */
          subtitle: () => string; 
          /* checkout.payLinks.couldntProcessPayment.cta */
          /* Go Back to Checkout */
          cta: () => string; 
        }; 
      }; 
      pricingPlans: {
        freeTrial: {
          /* checkout.pricingPlans.freeTrial.label */
          /* Free trial: {{number}} {{frequency}} */
          label: (data: Record<'number' | 'frequency', unknown>) => string; 
        }; 
        totalToday: {
          /* checkout.pricingPlans.totalToday.label */
          /* Pay now */
          label: () => string; 
        }; 
        duration: {
          /* checkout.pricingPlans.duration.label */
          /* Duration: {{number}} {{frequency}} */
          label: (data: Record<'number' | 'frequency', unknown>) => string; 
        }; 
        frequency: {
          years: {
            /* checkout.pricingPlans.frequency.years.plural */
            /* years */
            plural: () => string; 
          }; 
          days: {
            /* checkout.pricingPlans.frequency.days.plural */
            /* days */
            plural: () => string; 
          }; 
          month: {
            /* checkout.pricingPlans.frequency.month.singular */
            /* month */
            singular: () => string; 
          }; 
          weeks: {
            /* checkout.pricingPlans.frequency.weeks.plural */
            /* weeks */
            plural: () => string; 
          }; 
          week: {
            /* checkout.pricingPlans.frequency.week.singular */
            /* week */
            singular: () => string; 
          }; 
          months: {
            /* checkout.pricingPlans.frequency.months.plural */
            /* months */
            plural: () => string; 
          }; 
          year: {
            /* checkout.pricingPlans.frequency.year.singular */
            /* year */
            singular: () => string; 
          }; 
          day: {
            /* checkout.pricingPlans.frequency.day.singular */
            /* day */
            singular: () => string; 
          }; 
        }; 
        afterFreeTrial: {
          /* checkout.pricingPlans.afterFreeTrial.label */
          /* After free trial */
          label: () => string; 
        }; 
        startDate: {
          /* checkout.pricingPlans.startDate.label */
          /* Start date: {{date}} */
          label: (data: Record<'date', unknown>) => string; 
        }; 
      }; 
      emailField: {
        input: {
          error: {
            /* checkout.emailField.input.error.message */
            /* Enter an email address like example@mysite.com. */
            message: () => string; 
          }; 
        }; 
      }; 
      membersSignIn: {
        /* checkout.membersSignIn.title */
        /* Log in */
        title: () => string; 
        /* checkout.membersSignIn.subtitle */
        /* To purchase these items, log in to your account or sign up. */
        subtitle: () => string; 
        logIn: {
          /* checkout.membersSignIn.logIn.cta */
          /* Log In */
          cta: () => string; 
        }; 
        signUp: {
          /* checkout.membersSignIn.signUp.cta */
          /* Sign Up */
          cta: () => string; 
        }; 
      }; 
      giftCardError: {
        cantPurchaseGC: {
          /* checkout.giftCardError.cantPurchaseGC.label */
          /* Gift cards can't be used to purchase other gift cards. */
          label: () => string; 
        }; 
        couponCantPurchaseGiftCard: {
          /* checkout.giftCardError.couponCantPurchaseGiftCard.title */
          /* Coupons can't be applied when purchasing a gift card */
          title: () => string; 
          /* checkout.giftCardError.couponCantPurchaseGiftCard.subtitle */
          /* To complete your purchase, go back and remove the promo code. */
          subtitle: () => string; 
          /* checkout.giftCardError.couponCantPurchaseGiftCard.cta */
          /* Go Back to Checkout */
          cta: () => string; 
        }; 
        giftCardCantPurchaseGiftCard: {
          /* checkout.giftCardError.giftCardCantPurchaseGiftCard.subtitle */
          /* To complete your purchase, go back and remove the gift card code. */
          subtitle: () => string; 
          /* checkout.giftCardError.giftCardCantPurchaseGiftCard.title */
          /* Gift cards can't be used to purchase a gift card */
          title: () => string; 
          /* checkout.giftCardError.giftCardCantPurchaseGiftCard.cta */
          /* Go Back to Checkout */
          cta: () => string; 
        }; 
      }; 
      /* checkout.page_plan_untilCanceled */
      /* Until canceled */
      page_plan_untilCanceled: () => string; 
      giftCard: {
        inputCode: {
          /* checkout.giftCard.inputCode.ariaLabel */
          /* Input your gift card code */
          ariaLabel: () => string; 
        }; 
      }; 
    }; 
    cart: {
      validation: {
        error: {
          EMPTY_CART: {
            /* cart.validation.error.EMPTY_CART.title */
            /* You can't place your order just yet */
            title: () => string; 
            /* cart.validation.error.EMPTY_CART.content */
            /* It looks like your cart is empty. */
            content: () => string; 
            /* cart.validation.error.EMPTY_CART.button */
            /* Back to Site */
            button: () => string; 
          }; 
          FULL_OUT_OF_STOCK: {
            /* cart.validation.error.FULL_OUT_OF_STOCK.title */
            /* One or more of your items are sold out or no longer available */
            title: () => string; 
            /* cart.validation.error.FULL_OUT_OF_STOCK.content */
            /* Please go back and choose something else. */
            content: () => string; 
            /* cart.validation.error.FULL_OUT_OF_STOCK.button */
            /* Back to Site */
            button: () => string; 
          }; 
          PARTIAL_OUT_OF_STOCK: {
            /* cart.validation.error.PARTIAL_OUT_OF_STOCK.title */
            /* You can't place your order just yet */
            title: () => string; 
            /* cart.validation.error.PARTIAL_OUT_OF_STOCK.content */
            /* One or more items in your cart have sold out. To continue please remove them from your cart. */
            content: () => string; 
            /* cart.validation.error.PARTIAL_OUT_OF_STOCK.button */
            /* Got It */
            button: () => string; 
          }; 
          PAYMENT_IN_PROCESS: {
            /* cart.validation.error.PAYMENT_IN_PROCESS.title */
            /* Your last payment is still being processed */
            title: () => string; 
            /* cart.validation.error.PAYMENT_IN_PROCESS.content */
            /* You can't complete another order until your payment is processed. Please try again later. */
            content: () => string; 
            /* cart.validation.error.PAYMENT_IN_PROCESS.button */
            /* Got It */
            button: () => string; 
          }; 
        }; 
      }; 
    }; 
    app: {
      widget: {
        /* app.widget.welcome */
        /* Welcome */
        welcome: () => string; 
      }; 
      settings: {
        /* app.settings.label */
        /* Configure Widget */
        label: () => string; 
        tabs: {
          /* app.settings.tabs.main */
          /* Main */
          main: () => string; 
          /* app.settings.tabs.design */
          /* Design */
          design: () => string; 
        }; 
        defaults: {
          /* app.settings.defaults.greetingsPretext */
          /* to */
          greetingsPretext: () => string; 
        }; 
      }; 
    }; 
    chooseAddressModal: {
      /* chooseAddressModal.billing_address_title */
      /* Choose a billing address */
      billing_address_title: () => string; 
      /* chooseAddressModal.shipping_address_title */
      /* Choose an address */
      shipping_address_title: () => string; 
    }; 
    ecom: {
      email: {
        /* ecom.email.label */
        /* Email */
        label: () => string; 
      }; 
    }; 
  };
import { pathgen } from 'object-path-generator';
import React from 'react';

const createProxyImpl = <R extends string>(
  t = (...[k]: unknown[]) => k as R
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
) =>
  pathgen<R>(undefined, (path: string, ...options: any[]) => {
    const finalPath = path.split('.$value')[0];
    return t(finalPath, ...options);
  }) as unknown;

export function LocaleKeys<R extends string>(t: (...args: unknown[]) => R) {
  return createProxyImpl(t) as ILocaleKeys;
}

const LocaleKeysContext = React.createContext({} as ILocaleKeys);
export const LocaleKeysProvider: React.FC<{ translateFn?: (...args: unknown[]) => string; localeKeys?: ILocaleKeys; children?: React.ReactNode }> = ({ translateFn, localeKeys, children }) => {
    if (!translateFn && !localeKeys) { throw new Error('Either translateFn or localeKeys must be provided') }
    const value = (typeof translateFn === 'function' ? LocaleKeys(translateFn) : localeKeys) as ILocaleKeys
    return <LocaleKeysContext.Provider value={value}>{children}</LocaleKeysContext.Provider>;
  };
export const useLocaleKeys = () => React.useContext(LocaleKeysContext);
