import React, {useState} from 'react';
import {
  Button,
  CustomDialog,
  IconButton,
  Text,
  TextButtonPriority,
  TextTypography,
  TextButton,
} from 'wix-ui-tpa/cssVars';
import {st, classes} from './PolicyButtonWithDialog.st.css';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import CloseIcon from 'wix-ui-icons-common/on-stage/Close';

export enum PolicyButtonWithDialogDataHooks {
  PolicyDialog = 'PolicyButtonWithDialogDataHooks.Dialog',
  PolicyButton = 'PolicyButtonWithDialogDataHooks.Button',
  PolicyDialogTitle = 'PolicyButtonWithDialogDataHooks.DialogTitle',
  PolicyDialogXButton = 'PolicyButtonWithDialogDataHooks.PolicyDialogXButton',
  PolicyDialogCloseButton = 'PolicyButtonWithDialogDataHooks.DialogCloseButton',
  PolicyDialogDescription = 'PolicyButtonWithDialogDataHooks.DialogDescription',
}

interface PolicyButtonWithDialogButtonProps {
  show?: boolean;
  children: string;
  dataHook: string;
  dialogTitle: string;
  dialogDescription: () => React.ReactNode;
  className?: string;
  location: PolicyButtonLocation;
  policyType: PolicyType;
  onPolicyClicked: (location: PolicyButtonLocation, policyType: PolicyType) => void;
}
export enum PolicyButtonLocation {
  FORM = 'form',
  FOOTER = 'footer',
}

export enum PolicyType {
  TermsAndConditions = 'terms and conditions',
  PrivacyPolicy = 'privacy policy',
  ReturnPolicy = 'return policy',
  DigitalItemPolicy = 'digital item policy',
  CustomPolicy = 'custom policy',
  ItemPolicy = 'item policy',
  PaymentPolicy = 'payment policy',
  ContactUs = 'contact us',
}

export const PolicyButtonWithDialog = ({
  location,
  policyType,
  show,
  children,
  dialogTitle,
  dialogDescription,
  dataHook,
  className,
  onPolicyClicked,
}: PolicyButtonWithDialogButtonProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const localeKeys = useLocaleKeys();

  if (!show) {
    return null;
  }

  const openPolicyDialog = () => {
    setIsDialogOpen(true);
    onPolicyClicked(location, policyType);
  };

  const onKeyDown = (event: {key: string; preventDefault: () => void}) => {
    /* istanbul ignore else */
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault();
      openPolicyDialog();
    }
  };

  const preventClickThrough = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    /* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
    <span data-hook={dataHook} onClick={preventClickThrough}>
      <TextButton
        as={'a'}
        data-hook={PolicyButtonWithDialogDataHooks.PolicyButton}
        className={st(className, classes.link)}
        contentClassName={classes.linkInnerContent}
        priority={TextButtonPriority.link}
        onKeyDown={onKeyDown}
        onClick={openPolicyDialog}>
        {children}
      </TextButton>
      <CustomDialog
        data-hook={PolicyButtonWithDialogDataHooks.PolicyDialog}
        isOpen={isDialogOpen}
        className={classes.dialogWrapper}>
        <div className={classes.dialog}>
          <div className={classes.dialogBody}>
            <div className={classes.dialogHeader}>
              <Text
                tagName={'h2'}
                className={classes.headerText}
                data-hook={PolicyButtonWithDialogDataHooks.PolicyDialogTitle}
                typography={TextTypography.smallTitle}>
                {dialogTitle}
              </Text>
              <IconButton
                aria-label={localeKeys.checkout.informative_modal.close_button()}
                data-hook={PolicyButtonWithDialogDataHooks.PolicyDialogXButton}
                icon={<CloseIcon />}
                onClick={() => setIsDialogOpen(false)}
              />
            </div>
            <div className={classes.dialogContent}>
              <Text
                className={classes.dialogDescription}
                data-hook={PolicyButtonWithDialogDataHooks.PolicyDialogDescription}
                typography={TextTypography.runningText}>
                {dialogDescription()}
              </Text>
            </div>
            <div className={classes.dialogFooter}>
              <Button
                data-hook={PolicyButtonWithDialogDataHooks.PolicyDialogCloseButton}
                className={classes.button}
                upgrade={true}
                onClick={() => setIsDialogOpen(false)}>
                {localeKeys.checkout.informative_modal.close_button()}
              </Button>
            </div>
          </div>
        </div>
      </CustomDialog>
    </span>
  );
};
