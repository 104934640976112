import React from 'react';
import {st, classes} from './Header.st.css';
import {useControllerProps} from '../Widget/ControllerContext';
import {StoreNameAndLogo} from '../../../common/components/StoreNameAndLogo/StoreNameAndLogo';

export const Header = ({dataHook}: {dataHook: string}) => {
  const {
    paymentRequestSettingsStore: {paymentRequestSettings},
    navigationStore: {continueShoppingUrl},
    isOneColumnView,
  } = useControllerProps();

  return (
    <header className={classes.header}>
      <div className={st(classes.innerHeader)} data-hook={dataHook}>
        <StoreNameAndLogo
          continueShoppingUrl={continueShoppingUrl}
          logoUrl={paymentRequestSettings.logoUrl}
          storeName={paymentRequestSettings.storeName}
          checkoutHeader={paymentRequestSettings.checkoutHeader}
          isOneColumnView={isOneColumnView}
        />
      </div>
      <div className={classes.divider} />
    </header>
  );
};
