import {classes} from './HeaderLoader.st.css';
import React from 'react';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../../constants';

export const HeaderLoader = () => {
  const {experiments} = useExperiments();
  return (
    <header className={classes.header}>
      <div className={classes.frame}>
        <div
          key={'header-main'}
          className={
            experiments.enabled(SPECS.FixCheckoutHeaderCSS) ? classes.main : /* istanbul ignore next */ classes.mainOld
          }
        />
      </div>
      <div className={classes.divider} />
    </header>
  );
};
