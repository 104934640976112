import {classes} from './Layout.st.css';
import {MainLoader} from './MainLoader/MainLoader';
import {AsideLoader} from './AsideLoader/AsideLoader';
import React, {ReactNode, useEffect, useState} from 'react';
import {HeaderLoader} from './HeaderLoader/HeaderLoader';
import {useEnvironment, useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../constants';

export interface LayoutProps {
  isLoading: boolean;
  failedToFetch?: boolean;
  isOneColumnView?: boolean;
  Header: () => ReactNode;
  Aside: () => ReactNode;
  Footer: () => ReactNode;
  children: ReactNode;
  dataHook: string;
  dir: string;
}

export enum LayoutDataHooks {
  error = 'LayoutDataHooks.error',
  oneColumnViewSummary = 'LayoutDataHooks.oneColumnViewSummary',
  twoColumnViewSummary = 'LayoutDataHooks.twoColumnViewSummary',
}

export const Layout = ({
  dataHook,
  dir,
  isLoading,
  failedToFetch,
  isOneColumnView,
  Header,
  Aside,
  Footer,
  children,
}: LayoutProps) => {
  const {experiments} = useExperiments();
  const [loaded, setLoaded] = useState(false);
  const [style, setStyle] = useState<{zoom?: number; height: string} | undefined>(undefined);

  const {isMobile} = useEnvironment();
  useEffect(() => {
    if (!experiments.enabled(SPECS.ShouldFixZoomForCheckoutInClassic)) {
      return;
    }
    const zoom = getZoomForMobileClassicEditorFix(isMobile);
    setStyle(zoom ? {zoom, height: `calc(100vh / ${zoom})`} : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoaded(!isLoading);
  }, [isLoading]);
  /* istanbul ignore next */
  return (
    <div data-hook={dataHook} className={classes.root} dir={dir} style={style}>
      {loaded ? Header() : <HeaderLoader />}
      <div className={classes.container}>
        {isOneColumnView && (
          <aside data-hook={LayoutDataHooks.oneColumnViewSummary} className={classes.aside}>
            {loaded ? Aside() : null}
          </aside>
        )}
        <section className={classes.main}>{loaded ? children : <MainLoader />}</section>
        {!isOneColumnView && (
          <aside data-hook={LayoutDataHooks.twoColumnViewSummary} className={classes.aside}>
            {loaded ? Aside() : <AsideLoader />}
          </aside>
        )}
      </div>
      {loaded && Footer()}
      {/* TODO: get UX and error message */}
      {failedToFetch && (
        <div className={classes.error} data-hook={LayoutDataHooks.error}>
          Failed to Load!
        </div>
      )}
    </div>
  );
};

function getZoomForMobileClassicEditorFix(isMobile: boolean) {
  const editorBrand = (window as {commonConfig?: {brand: string}})?.commonConfig?.brand;
  const isClassicEditor = editorBrand === 'wix';
  if (!isMobile || !isClassicEditor) {
    return;
  }
  const width = window?.innerWidth || 320;
  const actualWidth = window?.screen?.availWidth || width;
  return width / actualWidth;
}
